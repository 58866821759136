export function useCatalogFiltersModal() {
  const open = useState('filterModalOpen', () => false)

  function openPanel() {
    open.value = true
  }

  function closePanel() {
    open.value = false
  }

  return {
    open,
    openPanel,
    closePanel,
  }
}
